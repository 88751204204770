<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      v-model="showform"
      max-width="1400px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
      v-if="isform"
    >
      <v-card>
        <v-card-title>
          <span> {{ title }} </span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <listitemspages
            :qSelect="Qselect"
            :qSelectall="Qselectall"
            :title="title"
            :PageResponse="'docs'"
            :AllResponse="'alldocs'"
            :headers="docs_headers"
            :field="'CREATED_AT'"
            :filename="'Documents'"
            :sheetname="'Documents'"
            :Add="editer"
            :del="editer"
            :del_disable="document.create_uid != $store.state.me.id"
            :showedit="false"
            @rowselect="FileSelect"
            @open="OpenFileForm"
            @hrf="PreviewFile"
            @col_btn1_click="OpenFileForm"
            @col_btn2_click="downloadItem"
            @delete="delete_file"
            :WhereExt="WhereExt"
            :key="klist"
          >
          </listitemspages>
        </v-card-text>
        <v-card-actions>
          <v-spacer> </v-spacer>

          <v-btn color="blue darken-1" text @click.stop="close"> Fermer </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <listitemspages
      :qSelect="Qselect"
      :qSelectall="Qselectall"
      :title="title"
      :PageResponse="'docs'"
      :AllResponse="'alldocs'"
      :headers="docs_headers"
      :field="'CREATED_AT'"
      :filename="'Documents'"
      :sheetname="'Documents'"
      :Add="editer"
      :del="editer"
      :del_disable="document.create_uid != $store.state.me.id"
      :showedit="false"
      @rowselect="FileSelect"
      @open="OpenFileForm"
      @hrf="PreviewFile"
      @col_btn1_click="OpenFileForm"
      @col_btn2_click="downloadItem"
      @delete="delete_file"
      :WhereExt="WhereExt"
      :key="klist"
      v-else
    >
    </listitemspages>
    <fileform
      :item="document"
      :well_id="well_id"
      :showForm="!isDocClose"
      :readonly="!editer"
      :extensions="ends"
      :key="ff"
      @close="isDocClose = true"
      @changed="klist++"
    >
    </fileform>
    <confirmdialog ref="confirm" />
  </v-form>
</template>
<script>
let ends = [];
import DELETE_DOC from "../graphql/File/UPDATE_DOC.gql";
import DOCS from "@/graphql/DOCS.gql";
import DOCS_PAGES from "@/graphql/DOCS_PAGES.gql";
import EXTENSION from "../graphql/File/EXTENSION.gql";
import { api } from "print/data.js";
export default {
  components: {
    listitemspages: () => import("./ListItemsPages.vue"),
    fileform: () => import("../components/FileForm.vue"),
    confirmdialog: () => import("./ConfirmDialog.vue"),
  },
  props: {
    showform: Boolean,
    editer: Boolean,
    doc_type: Number,
    well_id: Number,
    item: Object,
    isform: { type: Boolean, default: true },
    auth: String,
    title: { type: String, default: "Attached Documents" },
    headers: Array,
  },
  data() {
    return {
      document: {},
      isDocClose: true,
      ff: 900,
      klist: 0,
      return_list: [],
      WhereExt: [],
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
      ends: [],
      docs_headers: [
        {
          text: "No",
          value: "no",
          selected: true,
          sortable: false,
          index: 0,
        },
        {
          text: "Reference",
          value: "ref",
          enum: "REF",
          selected: true,
          index: 1,
        },
        {
          text: "Description",
          value: "description",
          enum: "DESCRIPTION",
          selected: true,
          index: 2,
        },
        {
          text: "File/Url",
          value: "filename",
          enum: "FILENAME",
          slot: "preview",
          preview: "preview",
          selected: true,
          index: 3,
        },
        {
          text: "Type",
          value: "file_type",
          enum: "FILE_TYPE",
          list: ends.map((elm) => elm.app),
          selected: true,
          index: 4,
        },
        {
          text: "Size (ko)",
          value: "size",
          enum: "SIZE",
          align: "end",
          slot: "cur33",
          selected: true,
          index: 5,
        },
        {
          text: "Date",
          value: "doc_date",
          enum: "DOC_DATE",
          slot: "date",
          selected: true,
          index: 6,
        },
        {
          text: "Url?",
          value: "isurl",
          slot: "checkbox",
          enum: "ISURL",
          selected: true,
          hiden: true,
          index: 7,
        },
        {
          text: "is Active?",
          value: "isActive",
          slot: "checkbox",
          enum: "ACTIVE",
          selected: true,
          hiden: true,
          index: 8,
        },
        {
          text: "User",
          value: "contact",
          enum: "CONTACT",
          selected: true,
          hiden: true,
          index: 9,
        },
        {
          text: "Test",
          value: "test",
          enum: "TEST",
          selected: true,
          hiden: true,
          index: 10,
        },
        {
          text: "",
          slot: "col_btn1",
          icon: "mdi-pencil",
          selected: true,
          width: "10",
          column: "create_uid",
          valeur: this.$store.state.me.id,
          tooltip: "Update document",
          sortable: false,
          index: 11,
        },
        {
          text: "",
          slot: "col_btn2",
          icon: "mdi-download",
          selected: true,
          width: "10",
          tooltip: "Download document",
          column1: "isurl",
          valeur1: 0,
          sortable: false,
          index: 12,
        },
      ],
    };
  },
  mounted() {
    if (this.headers) this.docs_headers = this.headers;
  },
  async created() {
    this.docs_headers[8].hiden = !this.$store.state.isadmin;
    if (this.doc_type == 0) {
      this.WhereExt = [
        { column: "DOC_TYPE", value: this.doc_type },
        {
          column: "ACTIVE",
          operator: "IN",
          value: [1],
        },
      ];
    } else if (this.item && this.doc_type) {
      this.WhereExt = [
        { column: "WELL_ID", value: this.well_id },
        { column: "DOC_TYPE", value: this.doc_type },
        { column: "DOC_ID", value: this.item.id },
        {
          column: "ACTIVE",
          operator: "IN",
          value: [1],
        },
      ];
      this.docs_headers[9].hiden = true;
      this.docs_headers[10].hiden = true;
    } else {
      this.WhereExt = [
        { column: "WELL_ID", value: this.well_id },
        { column: "CREATE_UID", value: this.$store.state.me.id },
        {
          column: "ACTIVE",
          operator: "IN",
          value: [1],
        },
      ];
      this.docs_headers[9].hiden = false;
      this.docs_headers[10].hiden = false;
    }
    this.klist++;
    let r = await this.requette(EXTENSION);
    if (r) {
      this.ends = r.extensions;
      ends = r.extensions;
      this.docs_headers[3].ends = this.ends
        .filter((elm) => elm.preview == 1)
        .map((elm) => elm.ext);
    }
  },
  computed: {
    Qselect() {
      return DOCS_PAGES;
    },
    Qselectall() {
      return DOCS;
    },
  },
  methods: {
    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },
    async delete_file(item) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Are you sure you want to delete this document?",
          { color: "orange darken-3" }
        )
      ) {
        let r = await this.maj(DELETE_DOC, {
          doc: { id: item.id, active: 0, write_uid: this.$store.state.me.id },
        });
        if (r) {
          this.$store.dispatch("Changed", true);
        }
      }
    },
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) this.$store.dispatch("Changed", true);
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    async PreviewFile(file) {
      if (file.isurl == 0) {
        let xhr = new XMLHttpRequest();
        xhr.open("GET", api + file.url, true);
        xhr.responseType = "arraybuffer"; // Use arraybuffer instead of blob
        xhr.onprogress = function (pe) {
          this.doc_download = true;
          this.progress_value = parseInt(
            Math.round((pe.loaded / (file.c_size * 1000)) * 100)
          );
          if (this.progress_value >= 100) this.doc_download = false;
        };
        xhr.onload = function () {
          if (this.status === 200) {
            const JSZip = require("jszip");
            var zip = new JSZip();
            zip.loadAsync(this.response).then((contents) => {
              let promises = [];

              Object.keys(contents.files).forEach((filename) => {
                promises.push(
                  contents
                    .file(filename)
                    .async("nodebuffer")
                    .then((content) => {
                      return {
                        filename,
                        content,
                      };
                    })
                );
              });

              Promise.all(promises).then((files) => {
                files.forEach((file) => {
                  let ok = false;
                  let t;
                  if (ends) {
                    for (let index = 0; index < ends.length; index++) {
                      const element = ends[index];
                      if (file.filename.endsWith(element.ext)) {
                        ok = true;
                        t = element.app;
                        break;
                      }
                    }
                  }
                  if (ok) {
                    let blob = new Blob([file.content], {
                      type: t,
                    });

                    // Open the PDF in a new window
                    const pdfContentUrl = URL.createObjectURL(blob);
                    window.open(
                      pdfContentUrl,
                      "_blank",
                      "toolbar=yes,scrollbars=yes,resizable=yes,width=1000,height=1000"
                    );
                  }
                });
              });
            });
          }
        };
        xhr.send();
      } else window.open(file.filename, "_blank");
    },

    async downloadItem(file) {
      if (
        await this.$refs.confirm.open("Confirmation", "Download this document?")
      ) {
        let xhr = new XMLHttpRequest();
        xhr.open("GET", api + file.url, true);
        xhr.responseType = "blob";
        xhr.onprogress = function (pe) {
          this.doc_download = true;
          this.progress_value = parseInt(
            Math.round((pe.loaded / (file.c_size * 1000)) * 100)
          );
          if (this.progress_value >= 100) this.doc_download = false;
        }.bind(this);

        xhr.onload = function () {
          if (this.status === 200) {
            const JSZip = require("jszip");
            var zip = new JSZip();
            zip.loadAsync(this.response).then(function (contents) {
              Object.keys(contents.files).forEach(function (filename) {
                zip
                  .file(filename)
                  .async("nodebuffer")
                  .then(function (content) {
                    let blob = new Blob([content], {
                      type: "application/pdf",
                    });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = file.filename;
                    link.click();
                  });
              });
            });
          }
        };
        xhr.send();
      }
    },
    close() {
      this.$emit("close");
    },
    FileSelect(item) {
      this.document = item;
    },
    OpenFileForm(item) {
      item.doc_type = this.doc_type;
      item.auth = this.auth;
      if (this.item) item.doc_id = this.item.id;
      this.document = item;
      this.isDocClose = false;
      this.ff++;
    },
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
    sumCol(key) {
      // sum data in give key (property)
      return this.list.reduce((a, b) => a + (b[key] || 0), 0);
    },
  },
};
</script>
